import React, { useState, useRef, useEffect } from "react";

export const useImageZoom = (initialScale = 1, minScale = 0.5, maxScale = 3) => {
    const [scale, setScale] = useState(initialScale);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [zoomHistory, setZoomHistory] = useState<number[]>([]);
    const [isHovering, setIsHovering] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);

    const handleZoomIn = () => {
        setZoomHistory((prev) => [...prev, scale]);
        setScale((prev) => Math.min(prev + 0.1, maxScale));
    };

    const handleZoomOut = () => {
        setZoomHistory((prev) => [...prev, scale]);
        setScale((prev) => Math.max(prev - 0.1, minScale));
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        if (imageRef.current) {
            setIsDragging(true);
            setDragStart({ x: e.clientX - imagePosition.x, y: e.clientY - imagePosition.y });
        }
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging && imageRef.current) {
            const newX = e.clientX - dragStart.x;
            const newY = e.clientY - dragStart.y;
            setImagePosition({
                x: newX,
                y: newY,
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleZoomUndo = () => {
        setZoomHistory((prev) => {
            if (prev.length > 0) {
                setScale(prev[prev.length - 1]);
                return prev.slice(0, -1);
            }
            return prev;
        });
    };

    const handleReset = () => {
        setImagePosition({ x: 0, y: 0 });
        setScale(initialScale);
    };

    useEffect(() => {
        if (!isHovering) return;
        const handleKeyDown = (e: KeyboardEvent) => {
            if ((e.metaKey || e.ctrlKey) && (e.key === "=" || e.key === "+")) {
                e.preventDefault();
                handleZoomIn();
            }

            if ((e.metaKey || e.ctrlKey) && (e.key === "-")) {
                e.preventDefault();
                handleZoomOut();
            }

            if ((e.metaKey || e.ctrlKey) && e.key === "0") {
                e.preventDefault();
                handleReset();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [scale, isHovering]);

    return {
        scale,
        isDragging,
        imagePosition,
        imageRef,
        setIsHovering,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleMouseLeave,
        handleZoomIn,
        handleZoomOut,
        handleReset,
        handleZoomUndo,
    };
};