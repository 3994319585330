import React from "react";
import UserFeedback from "../user_feedback";
import SmallPanel from "./small_panel";

const FeedbackPanel = ({ selectedFeedback, handleSelect }: { selectedFeedback?: any, handleSelect: (feedback: any) => void }) => {
    return (
        <SmallPanel>
            <div className="pl-2 pt-1">
                <p>Let us know your feedback</p>
                <UserFeedback selectedFeedback={selectedFeedback} handleSelect={handleSelect} />
            </div>
        </SmallPanel >
    )
}

export default FeedbackPanel;