import React, { useState } from "react";
import { Button } from "../../../common/components/ui/button";
import { ThumbsUp, ThumbsDown } from "../../../common/icons/icons";

const feedbackOptions = [
    { id: "up", icon: <ThumbsUp /> },
    { id: "down", icon: <ThumbsDown /> }
]

const UserFeedback = ({ selectedFeedback, handleSelect }: { selectedFeedback?: any, handleSelect: (feedback: any) => void }) => {
    const [selected, setSelected] = useState<"up" | "down" | null>(selectedFeedback);

    const feedbackSelect = (feedback: any) => {
        handleSelect(feedback);
        setSelected(selected === feedback ? null : feedback);
    }

    return (
        <div>
            <div className="space-x-3 pt-2">
                {feedbackOptions.map((option) => (
                    <Button
                        key={option.id}
                        variant="outline"
                        className={`rounded-full p-3 border-brand-gray-500 hover:border-white hover:bg-transparent ${selected === option.id ? "bg-brand-yellow hover:bg-brand-yellow  " : "fill-white"}`}
                        onClick={() => feedbackSelect(option.id)}>
                        {option.icon}
                    </Button>

                ))}

            </div>
        </div>
    )

}

export default UserFeedback;