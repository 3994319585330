import React, { useCallback, useEffect, useState } from "react";
import "./image_generator.css";
import { Button } from "../../common/components/ui/button";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import { useNavigate, useParams } from "react-router-dom";
import {
    GenerateMediaResult,
    GenerativeModel,
    GenerativeModelWeight,
    UpscaleRequest,
    VersionHistory
} from "./models/image_generator";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import InpaintModal from "./components/modal-components/inpaint_modal";
import VersionHistoryModal from "./components/modal-components/version_history_modal";
import {
    CopyIcon,
    DownloadIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    IconArrowCurrentFill,
    SaveIcon,
    UpscaleIcon,
} from "../../common/icons/icons";
import AspectRatioContent from "./components/modal-components/aspect_ratio";
import EditPrompt from "./components/modal-components/edit_prompt";
// import SaveImageContent from "./components/modal-components/save_image";
import VersionHistorySlider from "./components/version_history_slider";

const GeneratedImagePage = () => {
    const { taskQueueId: initialTaskQueueId } = useParams<{ taskQueueId: string }>();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(initialTaskQueueId || null);
    const [generativeData, setGenerativeData] = useState<GenerateMediaResult | null>(null);
    const [selectedVersion, setSelectedVersion] = useState<VersionHistory>();
    const [customCssForModal, setCustomCssForModal] = useState(false);
    const [versionHistory, setVersionHistory] = useState<VersionHistory[]>([]);
    const [showLoadingImage, setShowLoadingImage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [inpaintingView, setInpaintingView] = useState(false);
    const [modelList, setModelList] = useState<GenerativeModel[]>([]);
    const [headerText, setHeaderText] = useState<string>("");


    const [scale, setScale] = useState(1);

    const [actionToast, setActionToast] = useState<
        {
            showToast: boolean,
            toastMessage: string,
        }>({
            showToast: false,
            toastMessage: "",
        });
    // TODO combine vertical modal & modal content
    const [modalContent, setModalContent] = useState<React.ReactNode>(null);
    const [isVerticalModal, setIsVerticalModal] = useState(false);

    const { getGenerativeOutput,
        getVersionHistory,
        getModels,
        saveMyImage,
        upscaleImage,
        generateImageMedia,
        checkTaskQueueStatus } = useGenerativeAPI();
    const navigate = useNavigate();
    let intervalId: NodeJS.Timeout;

    const headerTexts = [
        "Ta-da! Your image is ready.",
        "Done and done! Your image is ready.",
        "Boom! Your image is here."
    ]

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * headerTexts.length);
        setHeaderText(headerTexts[randomIndex]);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productResponse, brandResponse, talentResponse] = await Promise.all([
                    getModels("USER_PRODUCT"),
                    getModels("BRAND"),
                    getModels("LICENSABLE_PROPERTY"),
                ]);

                const combinedModels = [
                    ...productResponse.map(model => ({ ...model, model_type: "Product" })),
                    ...brandResponse.map(model => ({ ...model, model_type: "Brand" })),
                    ...talentResponse.map(model => ({ ...model, model_type: "Talent" })),
                ]
                setModelList(combinedModels);
            } catch (error) {
                console.error("Error fetching models", error);
            }
        };
        fetchData();
    }, []);


    const downloadImage = () => {
        if (!generativeData) return;
        const link = document.createElement("a");
        link.href = selectedVersion ? selectedVersion.permalink : generativeData?.outputs[0].permalink;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast("Image downloaded");
    }

    const saveImage = async () => {
        setModalOpen(false);
        if (!taskQueueId || !generativeData) {
            showToast("Error saving image");
            return;
        }
        try {
            await saveMyImage(taskQueueId);
            showToast("Image saved to My Images");
        } catch (error) {
            showToast("Error saving image");
            console.error("Error saving image", error);
        }
    }

    const fetchUpscaleImage = async () => {
        if (!selectedVersion || !taskQueueId) {
            showToast("Error upscaling image");
            return;
        }

        const payload: UpscaleRequest = {
            generative_output_id: selectedVersion.generative_output_id,
            file_id: selectedVersion.file_id,
            seed: generativeData?.seed,
            parent_task_id: generativeData?.task_queue_id
        }

        try {
            setShowLoadingImage(true);
            const response = await upscaleImage(payload);
            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error upscaling image");
        }
    }

    // TODO consolidate methods
    const handleSavePrompt = useCallback((newPrompt: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, prompt: newPrompt }
        });

        regenerateImage(newPrompt, generativeData?.aspect_ratio, generativeData?.model_weights, undefined, generativeData?.task_queue_id, "generate_media");
    }, [generativeData]);

    // TODO fix regenerateImage methods
    const regenerateImage = async (prompt?: string, aspect_ratio?: string, model_weights?: GenerativeModelWeight[], image_file_id?: string, parent_task_id?: string, action?: string) => {
        setShowLoadingImage(true);
        try {
            const response = await generateImageMedia({
                prompt: prompt || generativeData?.prompt || "",
                aspect_ratio: aspect_ratio || "SQUARE",
                image_file_id: image_file_id || selectedVersion?.file_id || generativeData?.outputs[0].file_id || undefined,
                model_weights: model_weights || generativeData?.model_weights || [],
                seed: generativeData?.seed,
                parent_task_id: parent_task_id || undefined,
                action: action || "generate_media"
            });

            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const generateVariations = async () => {
        const prompt_text = generativeData?.prompt || "Generate variations";
        // if (!prompt_text) {
        //     // TODO: Get a real dialog box here
        //     prompt_text = prompt("Please describe what you would like to see in the variation")
        // }
        // if (!prompt_text) {
        //     prompt_text = "Generate variations"
        // }
        return regenerateImage(
            prompt_text,
            generativeData?.aspect_ratio,
            generativeData?.model_weights,
            selectedVersion?.file_id,
            generativeData?.task_queue_id,
            "generate_media")
    }


    const buttonMap = [
        {
            label: "Generate variation",
            icon: <CopyIcon />,
            action: () => generateVariations()
        },
        {
            label: "Upscale",
            icon: <UpscaleIcon />,
            action: () => fetchUpscaleImage()
        },
        {
            label: "Download",
            icon: <DownloadIcon />,
            action: () => downloadImage()
        },
        {
            label: "Edit aspect ratio",
            icon: <EditAspectRatioIcon />,
            action: () => openModal(<AspectRatioContent currentAspectRatio={generativeData?.aspect_ratio} onSave={editAspectRatio} />)
        },
        {
            label: "Edit directly",
            icon: <EditDirectlyIcon />,
            action: () => { setInpaintingView(true) }
        },
        {
            label: "Edit prompt",
            icon: <EditPromptIcon />,
            action: () => openModal(<EditPrompt
                prompt={generativeData?.prompt || ""}
                onSave={handleSavePrompt} selectedModels={generativeData?.model_weights} />
            )
        },
        {
            label: "Save",
            icon: <SaveIcon />,
            // Save image displays toast until functionality for saving to project is implemented
            // action: () => openModal(SaveImageContent(saveImage), true),
            action: () => saveImage()
        },
    ]

    const editAspectRatio = useCallback((newAspectRatio: string) => {
        setModalOpen(false);
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, aspect_ratio: newAspectRatio }
        });
        regenerateImage("", newAspectRatio, generativeData?.model_weights, selectedVersion?.file_id, generativeData?.id, "outpainting");
    }, [generativeData, selectedVersion]);

    useEffect(() => {
        const fetchGenerativeData = async () => {
            if (!taskQueueId) return;

            try {
                const [generativeData, childVersionHistory] = await Promise.all([
                    getGenerativeOutput(taskQueueId),
                    getVersionHistory(taskQueueId)
                ]);

                const parentVersionHistory: VersionHistory[] = generativeData.outputs.map(output => ({
                    generative_output_id: output.generative_output_id,
                    file_id: output.file_id,
                    permalink: output.permalink,
                    asset_type: output.asset_type,
                    created_at: output.created_at,
                    action: generativeData.action,
                    parentTaskId: generativeData.parentTaskId,
                    seed: generativeData.seed,
                    task_queue_id: taskQueueId,
                    aspect_ratio: generativeData.aspect_ratio,
                    height: generativeData.height,
                    width: generativeData.width,
                    prompt: generativeData.prompt,
                    inpaint_prompt: generativeData.inpaint_prompt,
                }));

                const combinedVersionHistory = [...parentVersionHistory, ...childVersionHistory];
                combinedVersionHistory.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

                setVersionHistory(combinedVersionHistory);
                setSelectedVersion(combinedVersionHistory[0]);
                setGenerativeData(generativeData);

            } catch (error) {
                console.error("Error fetching task queue data", error);
            }
        };

        fetchGenerativeData();
    }, [taskQueueId]);


    const openModal = (content: React.ReactNode, verticalModal?: boolean) => {
        setIsVerticalModal(verticalModal || false);
        setModalContent(content);
        setModalOpen(true);
    }

    const showToast = (message: string) => {
        setModalOpen(false);
        setActionToast({ showToast: true, toastMessage: message });
        setTimeout(() => {
            setActionToast(prevState => ({ ...prevState, showToast: false, toastMessage: "" }));
        }, 3000);
    }


    const generateInpaint = async (inpaintPrompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null, inpaintImageFileId: string | null) => {
        if (!mask) {
            showToast("Please select the area to inpaint");
            return;
        }
        setShowLoadingImage(true);
        try {
            const response = await generateImageMedia({
                prompt: generativeData ? generativeData.prompt : inpaintPrompt,
                aspect_ratio: generativeData?.aspect_ratio || "SQUARE",
                image_file_id: selectedVersion?.file_id || "",
                model_weights: modelWeights || [],
                seed: generativeData?.seed,
                mask: mask,
                parent_task_id: generativeData?.task_queue_id || undefined,
                inpaint_prompt: inpaintPrompt,
                inpaint_image_file_id: inpaintImageFileId,
            });

            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error("Error generating image", error);
        }
    }

    const newFetchGenOutput = async (taskQueueId: string) => {
        // Will we always only have one image returned at a time?
        try {
            const response = await getGenerativeOutput(taskQueueId);
            const latestVariation = response.outputs.map(output => ({
                generative_output_id: output.generative_output_id,
                file_id: output.file_id,
                permalink: output.permalink,
                asset_type: output.asset_type,
                action: response.action,
                parentTaskId: response.parentTaskId,
                seed: response.seed,
                task_queue_id: response.task_queue_id,
                aspect_ratio: response.aspect_ratio,
                height: response.height,
                width: response.width,
                prompt: response.prompt,
                inpaint_prompt: response.inpaint_prompt,
                created_at: output.created_at,
            }))
            versionHistory.push(latestVariation[0]);
            setSelectedVersion(latestVariation[0]);
        } catch (error) {
            console.error("Error fetching generative output", error);
        }
    }

    const checkStatusMethod = async (updatedTaskQueueId: string, intervalId: NodeJS.Timeout) => {
        try {
            const response = await checkTaskQueueStatus(updatedTaskQueueId);
            let failedTask = null;
            let completedTask = null;
            for (let i = response.length - 1; i >= 0; i--) {
                if (response[i].status === "completed") {
                    completedTask = response[i];
                    break;
                }
                if (response[i].status === "failed") {
                    failedTask = response[i];
                    break;
                }
            }

            if (failedTask) {
                setShowLoadingImage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }
            if (completedTask && completedTask.progress_status === "failed") {
                setShowLoadingImage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + completedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                clearInterval(intervalId);
                setShowLoadingImage(false);
                showToast(headerText);
                newFetchGenOutput(updatedTaskQueueId);
                return;
            }

        } catch (error) {
            console.error("Error checking status", error);
        }
    }

    const handleTimeout = () => {
        clearInterval(intervalId);
        setShowLoadingImage(false);
        showToast("Oops! It's taking a little too long to create your image. You can retry in a moment!");
    }

    return (
        <div>
            <DialogModal
                isOpen={modalOpen}
                onOpenChange={setModalOpen}
                onClose={() => { setModalOpen(false); setModalContent(null) }}
                className={isVerticalModal ? "w-[501px] h-[660px]" : ""}
                variant={!isVerticalModal ? "large" : ""}
                customClass={customCssForModal}
            >
                {modalContent}
            </DialogModal>
            <InpaintModal
                isInpaintModalOpen={inpaintingView}
                setIsInpaintModalOpen={setInpaintingView}
                selectedVariant={selectedVersion}
                modelList={modelList}
                generateInpaint={generateInpaint} />
            <div className="flex flex-row justify-between mt-[132px] mx-[120px]">
                <Button variant="primary-negative" className=" fill-white hover:bg-brand-yellow hover:text-black hover:fill-black hover:border-brand-yellow"
                    onClick={() => { navigate(`/image/new/${taskQueueId}`, { state: { generativeData } }); }}>
                    <><IconArrowCurrentFill />Start over</>
                </Button>
                <Button variant="primary-negative" className="hover:bg-brand-yellow hover:text-black hover:border-brand-yellow"
                    onClick={() => { openModal(<VersionHistoryModal versionHistory={versionHistory} />); setCustomCssForModal(true) }}>
                    Version history
                </Button>
            </div>
            <div className="pt-[52px] px-[120px] flex flex-row gap-10">
                {generativeData &&
                    <div className="w-[600px] h-auto overflow-hidden rounded-lg">
                        <img
                            className="w-full h-auto object-cover"
                            style={{ transform: `scale(${scale})`, transition: "transform 0.2s" }}
                            src={selectedVersion?.permalink ?? ""}
                            alt="Generated Image"
                        />
                    </div>
                }


                <div className="flex flex-col gap-6 w-[668px]">
                    <div>
                        <h1 className="mb-3 text-xxl-serif">Image overview</h1>
                        <p>Explore and edit your generated images</p>
                    </div>

                    <div>
                        <p className="font-semibold pb-6">Versions</p>
                        <VersionHistorySlider versionHistory={versionHistory} selectedVariant={selectedVersion} setSelectedVariant={setSelectedVersion} showLoadingImage={showLoadingImage} />
                    </div>

                    <p className="font-semibold">Options</p>
                    <div className="grid md:grid-cols-2 gap-4 sm:grid-cols-1">
                        {buttonMap.map((button, index) => (
                            <Button key={index} variant="outline-official" onClick={button.action}
                                className="h-[24px] w-full p-6 rounded-[20px] flex gap-5 font-normal border-gray-300 justify-start overflow-hidden hover:fill-white">
                                <>
                                    <div className="flex-shrink-0">{button.icon}</div>
                                    {button.label}</>
                            </Button>
                        ))}
                    </div>

                </div>

            </div>
            {/* TODO clear toast */}
            {actionToast.showToast && <div className="absolute rounded-3xl right-9 bg-black px-6 py-5 text-white w-[453px]">{actionToast.toastMessage}</div>}
        </div>



    )

}


export default GeneratedImagePage;
