import React, { useState, useRef, useEffect } from "react";
import { VersionHistory } from "../models/image_generator";
import { SliderLeft, SliderRight, SelectedGenIcon } from "../../../common/icons/icons";
import loading from "../../../common/components/ui/loading.gif";
import { Button } from "../../../common/components/ui/button";

const VersionHistorySlider = ({
    versionHistory,
    selectedVariant,
    setSelectedVariant,
    showLoadingImage
}: {
    versionHistory: VersionHistory[],
    selectedVariant?: VersionHistory | null,
    setSelectedVariant: (variant: VersionHistory) => void,
    showLoadingImage: boolean
}) => {
    // const [currentVariant, setCurrentVariant] = useState(selectedVariant);
    const [isOverflow, setIsOverflow] = useState(false);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (sliderRef.current) {
                const clientWidth = sliderRef.current.clientWidth;
                const scrollWidth = sliderRef.current.scrollWidth;

                const overflow = scrollWidth > clientWidth;
                setIsOverflow(overflow);

                if (sliderRef.current) {
                    setCanScrollLeft(sliderRef.current.scrollLeft > 0);
                    setCanScrollRight(
                        sliderRef.current.scrollLeft + clientWidth < scrollWidth
                    );
                }
            }
        };

        checkOverflow();

        window.addEventListener("resize", checkOverflow);

        const currentRef = sliderRef.current;
        const handleScroll = () => checkOverflow();
        currentRef?.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("resize", checkOverflow);
            currentRef?.removeEventListener("scroll", handleScroll);
        };
    }, [versionHistory]);

    const scroll = (direction: "left" | "right") => {
        if (sliderRef.current) {
            const scrollAmount = sliderRef.current.clientWidth * 0.8;
            sliderRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth"
            });
        }
    };

    return (
        <div className="flex flex-row gap-3">
            <div className="flex flex-row gap-3 w-11/12 overflow-x-auto" ref={sliderRef}
                style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none"
                }}>
                {versionHistory.map((variation, index) => (
                    <div key={index} className="relative shrink-0">
                        <img onClick={
                            () => {
                                setSelectedVariant(variation);
                            }} className={`bg-gray-400 w-[116px] object-cover h-36 rounded-lg ${variation === selectedVariant ? "border-4 border-brand-yellow" : ""}`}
                            src={variation.permalink} />
                        {variation === selectedVariant &&
                            <div className="absolute top-2 right-2"> <SelectedGenIcon /> </div>}
                    </div>
                ))}
                {!isOverflow && showLoadingImage && (
                    <div className="w-[116px] bg-brand-yellow rounded-lg flex justify-center items-center">
                        <img
                            className="h-10"
                            src={loading}
                            alt="Loading..."
                        />
                    </div>
                )}
            </div>
            {isOverflow && showLoadingImage && (
                <div className="w-[116px] bg-brand-yellow rounded-lg flex flex-shrink-0 justify-center items-center">
                    <img
                        className="h-10"
                        src={loading}
                        alt="Loading..."
                    />
                </div>
            )}

            {isOverflow && versionHistory.length > 1 && (
                <div className="flex flex-col gap-3 justify-center">
                    <Button variant="outline" disabled={!canScrollRight} className=" px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white" onClick={() => scroll("right")}>
                        <SliderRight />
                    </Button>
                    <Button variant="outline" disabled={!canScrollLeft} className="px-3 stroke-white border-brand-gray-500 hover:bg-transparent hover:border-white" onClick={() => scroll("left")}>
                        <SliderLeft />
                    </Button>
                </div>

            )}
        </div>
    )
}

export default VersionHistorySlider;