import React, { useCallback, useState } from "react";
import Panel from "./panel";
import { Button } from "../../../../common/components/ui/button";
import VaultDropdown from "../vault_dropdown";
import { GenerativeModel } from "../../models/image_generator";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../common/components/ui/popover";
import { InfoIcon } from "../../../../common/icons/icons";

const EditDirectlyPanel = ({
    modelList, cursorSize,
    setCursorSize,
    resetDraw,
    undoLastDraw,
    generateInpaint,
    mask,
    expanded,
    handleToggleExpand,
    showInpaint,
    closePanel }: {
        modelList: GenerativeModel[],
        cursorSize: number,
        setCursorSize: (size: number) => void,
        resetDraw: () => void,
        undoLastDraw: () => void,
        generateInpaint: (prompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null) => void,
        showInpaint: (mask: any) => void,
        mask: any,
        closePanel?: () => void,
        expanded: boolean | undefined,
        handleToggleExpand: () => void
    }) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const [inpaintPrompt, setInpaintPrompt] = useState<string>("");
    // const [ selectedInpaintFile, setSelectedInpaintFile ] = useState<File | null>(null);

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setInpaintPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${promptWithoutOldTag} ${newGenerativeTag}`.trim();

            return updatedPrompt;
        });
    }

    const handleBrushSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCursorSize(Number(e.target.value));
    }

    const triggerGenerateInpaint = useCallback(() => {
        const modelWeights = [];
        if (selectedModel?.entity_type === "LICENSABLE_PROPERTY") {
            modelWeights.push({ id: selectedModel.id, weight: 0.8 });
        }
        if (selectedModel?.entity_type === "USER_PRODUCT") {
            modelWeights.push({ id: selectedModel.id, weight: (modelWeights.length === 0 ? 0.8 : 0.2) });
        }
        generateInpaint(
            inpaintPrompt,
            modelWeights,
            mask,
            // selectedInpaintFile?.id || null
        )
        resetDraw();
        setInpaintPrompt("");
    }, [selectedModel, inpaintPrompt, mask]);

    return (
        <Panel toggleExpanded={handleToggleExpand} isExpanded={expanded} title="Edit directly" closePanel={closePanel}>
            <div className="space-y-6">
                <div>
                    <p>Brush size</p>
                    <input className="accent-brand-yellow bg-gray-100 focus:ring-0 w-full focus:outline-none border-none" type="range"
                        value={cursorSize}
                        min="10"
                        max="100"
                        onChange={handleBrushSizeChange} />
                    <div className="text-sm-sans flex justify-between">
                        <p>0</p>
                        <p>100</p>
                    </div>
                </div>
                <div>
                    <p className="">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                <div className="flex flex-col gap-3">
                    <div className="flex gap-2">
                        <p className="">Smart Touch-Up description</p>
                        <Popover>
                            <PopoverTrigger>
                                <InfoIcon />
                            </PopoverTrigger>
                            <PopoverContent className="text-base bg-brand-yellow border-none">
                                Any editing you do to this image will only the inpainted area
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="relative">
                        <textarea
                            placeholder="Enter image prompt here"
                            className="bg-gray-700 rounded-lg p-4 text-sm-sans text-white border-none outline-none h-[145px] w-full resize-none"
                            value={inpaintPrompt} onChange={(e) => setInpaintPrompt(e.target.value)}
                        />
                    </div>

                    {/* {isPromptLoading ? (
                            <Button
                                className="w-44 border-none absolute hover:bg-brand-yellow bottom-5 right-5"
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                            >
                                <img className="h-4" src={loading} alt="Loading..." />
                            </Button>
                        ) : (
                            <Button
                                onClick={enhanceTextPrompt}
                                type="button"
                                variant="primary"
                                className="flex gap-2 border-none w-44 hover:bg-black hover:text-white hover:fill-white absolute bottom-5 right-5"
                            >
                                <>
                                    <Sparkles />
                                    Rewrite
                                </>
                            </Button>
                        )} */}
                </div>
                <Button onClick={triggerGenerateInpaint} className="w-full border-none" variant="primary">Generate inpainting</Button>
                <div className="flex flex-row gap-2">
                    <Button className="rounded-[10px]" onClick={resetDraw}>Reset inpainting</Button>
                    <Button className="rounded-[10px]" onClick={undoLastDraw} >Undo</Button>
                </div>

                {/* <Button onClick={() => showInpaint(mask)}>Show inpaint</Button> */}
            </div>
        </Panel>
    )
}

export default EditDirectlyPanel;