import React from "react";
import { Panel } from "../models/image_generator";

const ImageSideNavPanel = ({ panels, onPanelClick, renderPanelContent }: { panels: Panel[], onPanelClick: (panelId: string) => void, renderPanelContent: (panelId: string) => React.ReactNode }) => {
    return (
        <div className="bg-gray-900 w-[91px] h-full rounded-xl flex flex-col gap-2 items-center py-7">
            {panels.map((panel, index) => (
                <div key={index} className="relative">
                    <div onClick={() => onPanelClick(panel.id)} className={`flex justify-center relative items-center fill-white w-10 h-10 rounded-xl ${panel.active ? "bg-brand-gray-600" : ""}`}>{panel.icon}</div>
                    {panel.active && panel.isShort && (
                        <div>
                            {renderPanelContent(panel.id)}
                        </div>
                    )}

                </div>
            ))}
        </div>
    )
}

export default ImageSideNavPanel;