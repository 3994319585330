import React, { useState } from "react";
import { PanelXIcon } from "../../../../common/icons/icons";


const Panel = ({ children, title, isExpanded, toggleExpanded, closePanel }: { children: React.ReactNode, title: string, isExpanded?: boolean, toggleExpanded?: () => void, closePanel?: () => void }) => {
    return (
        <div className="bg-gray-900 select-none text-white rounded-xl p-5 ">
            <div onClick={toggleExpanded} className={`flex justify-between ${isExpanded && "pb-4"}`}>
                {title}
                {closePanel && <div onClick={closePanel}> <PanelXIcon /></div>}
            </div>
            {isExpanded &&
                <div>
                    {children}
                </div>
            }
        </div>
    )
}

export default Panel;