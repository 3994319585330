import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "./image_details.css";
import { Textarea } from "../../../common/components/ui/textarea";
import { Button } from "../../../common/components/ui/button";
import { PencilIcon, Sparkles, CircleX } from "../../../common/icons/icons";
import IpVaultModal from "./modal-components/IP_vault_modal";
import { useGenerativeAPI } from "../../../api/generative_ai_api";
import loading_light from "../../../common/components/ui/loading_light.gif";
import AspectRatioCardGroup from "./aspect_ratio_card";
import { GenerativeModel } from "../models/image_generator";

const ImageDetails = ({ form, errors, selectedModels, modelList, aspectRatios, onVaultSelect }: { form: any, errors: any, modelList: GenerativeModel[], selectedModels: any, aspectRatios: any, onVaultSelect: (vault: GenerativeModel | null) => void }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isIpVaultOpen, setIsIpVaultOpen] = useState(false);
    const [selectedVault, setSelectedVault] = useState<GenerativeModel | null>(selectedModels[0]);
    const { enhancePrompt } = useGenerativeAPI();
    const { setValue } = useFormContext();

    const removeSelectedVault = () => {
        onVaultSelect(null);
    }

    useEffect(() => {
        setSelectedVault(selectedModels[0] || null);
    }, [selectedModels]);

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const models = []
        if (selectedModels && selectedModels.length > 0) {
            models.push(selectedModels[0].id);
        }
        const response = await enhancePrompt(form.getValues("prompt"), models);
        if (response && response.enhanced) {
            setValue("prompt", response.enhanced);
        }
        setIsLoading(false);
    }

    const handleAspectRatioChange = (newAspectRatio: string) => {
        setValue("aspect_ratio", newAspectRatio);
    }

    return (
        <div className="space-y-12">
            <div className="flex w-full gap-14">
                <div className="flex md:flex-row flex-col gap-12 justify-between">
                    <div className="relative md:w-[519px]">
                        <div className="relative">
                            <Textarea
                                placeholder="Enter image prompt here"
                                className="bg-gray-900 text-black text-sm-sans font-light border-none h-[173px] p-5 w-full resize-none overflow-y-auto"
                                value={form.watch("prompt")}
                                onChange={(e) => setValue("prompt", e.target.value)}
                            />
                            {isLoading ? (
                                <Button
                                    className="flex w-[96px] gap-1 border-gray-600 h-[35px] p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                                    onClick={enhanceTextPrompt}
                                    type="button"
                                    variant="outline"
                                >
                                    <img className="h-4" src={loading_light} alt="Loading..." />
                                </Button>
                            ) : (
                                <Button
                                    onClick={enhanceTextPrompt}
                                    type="button"
                                    variant="outline"
                                    className="flex gap-1 w-[96px] h-[35px] border-gray-600 p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                                >
                                    <>
                                        <Sparkles />
                                        Rewrite
                                    </>
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="space-y-5 pt-5">
                        <div className="flex flex-row gap-3 items-center">
                            <p className="font-semibold text-xl">Select IP vault</p>
                            <p className="text-sm-sans">Optional</p>
                        </div>
                        <Button onClick={() => setIsIpVaultOpen(true)} variant="primary-negative-invert" type="button" className="border-none">Select</Button>
                        <IpVaultModal
                            isIpModalOpen={isIpVaultOpen}
                            setIsIpModalOpen={setIsIpVaultOpen}
                            selectedVaults={selectedModels}
                            modelList={modelList}
                            confirmIpSelect={onVaultSelect} />
                        {selectedVault && (
                            <div className="bg-gray-100 text-black w-[284px] p-4 rounded-xl flex flex-row gap-3 items-center justify-between">
                                <div className="flex flex-row items-center gap-3">
                                    <img className="object-cover h-[70px] w-[70px] rounded-[20px]" src={selectedVault.thumbnail_image} />
                                    <div className="flex flex-col">
                                        <p className="uppercase text-sm font-semibold">{selectedVault.model_type}</p>
                                        <p className="">{selectedVault.name}</p>
                                    </div>
                                </div>
                                <div onClick={removeSelectedVault} >
                                    <CircleX />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <AspectRatioCardGroup options={aspectRatios} selected={form.watch("aspect_ratio") ?? "SQUARE"}
                        onSelect={handleAspectRatioChange} size="small" label="Aspect ratio" /> */}

                    <div className="flex items-center ">
                        <Button variant="primary" className="min-w-[341px] min-h-[65px] text-base font-semibold">Generate image</Button>
                    </div>
                </div>
            </div>



        </div>
    )

}

export default ImageDetails;

