import React, { useState, useEffect } from "react";
import Panel from "./panel";
import { useGenerativeAPI } from "../../../../api/generative_ai_api";
import { GenerativeModel, GenerateMediaResult } from "../../models/image_generator";
import VaultDropdown from "../vault_dropdown";
import { Button } from "../../../../common/components/ui/button";
import { Sparkles } from "../../../../common/icons/icons";
import loading_light from "../../../../common/components/ui/loading_light.gif";

const DescriptionPanel = ({ generativeData, modelList, expanded, updatePrompt, handleToggleExpand, closePanel }: { expanded: boolean | undefined, generativeData?: GenerateMediaResult | null, modelList: GenerativeModel[], updatePrompt: (newPrompt: string) => void, handleToggleExpand: () => void, closePanel: () => void }) => {
    const [selectedModel, setSelectedModel] = useState<GenerativeModel | null>(null);
    const [prompt, setPrompt] = useState<string>(generativeData?.prompt || "");
    const [isLoading, setIsLoading] = useState(false);

    const { enhancePrompt } = useGenerativeAPI();

    useEffect(() => {
        const tagMatch = prompt.match(/#(\w+)/);
        if (!tagMatch) {
            setSelectedModel(null);
        }
        if (tagMatch) {
            const generativeTag = tagMatch[1];
            const matchingModel = modelList.find(
                model => model.generative_tag === generativeTag
            );

            if (matchingModel) {
                setSelectedModel(matchingModel);
            }
        }
    }, [prompt, modelList]);

    const handleSelectModel = (model: any) => {
        setSelectedModel(model);
        const regex = /#\w+/g;
        setPrompt((prevPrompt) => {
            const promptWithoutOldTag = prevPrompt.replace(regex, "").trim();

            const newGenerativeTag = `#${model.generative_tag}`;
            const updatedPrompt = `${newGenerativeTag} ${promptWithoutOldTag}`.trim();

            return updatedPrompt;
        });
    }

    const enhanceTextPrompt = async () => {
        setIsLoading(true);
        const response = await enhancePrompt(prompt, selectedModel ? [selectedModel.id] : []);
        if (response && response.enhanced) {
            setPrompt(response.enhanced);
        }
        setIsLoading(false);
    }

    const handleSave = () => {
        updatePrompt(prompt)
    };

    return (
        <Panel isExpanded={expanded} toggleExpanded={handleToggleExpand} title="Description" closePanel={closePanel}>
            <div className="h-full space-y-6">
                <div className="relative">
                    <textarea
                        placeholder="Enter image prompt here"
                        className="bg-gray-700 rounded-lg p-4 text-sm-sans text-white border-none outline-none h-[145px] w-full resize-none overflow-y-auto"
                        value={prompt} onChange={(e) => setPrompt(e.target.value)}
                    />
                    {isLoading ? (
                        // TODO fix bg-black
                        <Button
                            className="flex w-[96px] bg-black gap-1 border-gray-600 h-[35px] p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                            onClick={enhanceTextPrompt}
                            type="button"
                            variant="outline"
                        >
                            <img className="h-4" src={loading_light} alt="Loading..." />
                        </Button>
                    ) : (
                        <Button
                            onClick={enhanceTextPrompt}
                            type="button"
                            variant="outline"
                            className="flex gap-1 bg-black w-[96px] h-[35px] border-gray-600 p-2 hover:bg-black hover:text-white fill-white absolute bottom-5 right-5 z-10"
                        >
                            <>
                                <Sparkles />
                                Rewrite
                            </>
                        </Button>
                    )}
                </div>
                <div>
                    <p className="font-semibold">Vault</p>
                    <VaultDropdown modelData={modelList} selectedModelId={selectedModel?.id} onSelect={handleSelectModel} />
                </div>
                <Button onClick={handleSave} className="w-full border-none" variant="primary">Generate</Button>
            </div>
        </Panel>
    )
}

export default DescriptionPanel;