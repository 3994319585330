import React from "react";
import { Button } from "../../../../common/components/ui/button";
import { VersionHistory } from "../../models/image_generator";
import { format } from "date-fns";

const VersionHistoryModal = ({ versionHistory }: { versionHistory: VersionHistory[] }) => {

    // very hacky until we include action in data;
    const compareVersions = (prevItem: VersionHistory, currentItem: VersionHistory) => {
        if (currentItem.action === "upscale") {
            return "Upscale image"
        } else if (currentItem.action === "generate_media") {
            return "Generate variation";
        } else if (currentItem.inpaint_prompt) {
            return `Edit image: ${currentItem.inpaint_prompt}`;
        } else if (currentItem.prompt !== prevItem.prompt) {
            return `"${currentItem.prompt}"`;
        } else if (currentItem.aspect_ratio !== prevItem.aspect_ratio) {
            return "Update aspect ratio";
        } else if (prevItem.toString() === currentItem.toString()) {
            return "Variation of inital prompt";
        } else {
            return `"${currentItem.prompt}"`;
        }
    }

    const formattedVersionHistory = versionHistory.map((item, index) => {
        const prevItem = index > 0 ? versionHistory[index - 1] : null;
        return {
            ...item,
            formattedDate: format(new Date(item.created_at), "M/d/yy"),
            formattedTime: format(new Date(item.created_at), "h:mma"),
            cliffnoteAction: prevItem ? compareVersions(prevItem, item) : item.prompt ? `Generate image: "${item.prompt}"` : item.action ? item.action : "Generate image",
        }
    });


    return (
        <div className="px-[35px] py-6">
            <h2 className="font-sans text-lg font-semibold not-italic flex justify-start pb-6">Version history</h2>
            {formattedVersionHistory.map((versionHistory, index) => (
                <div className="flex flex-row justify-between items-center px-4 pb-5" key={index}>
                    <div className="flex flex-row items-center gap-5">
                        <p className="font-semibold">{index + 1}.</p>
                        <img className="w-[72px] h-[72px] rounded-full" src={versionHistory.thumbnailImage ? versionHistory.thumbnailImage : versionHistory.permalink} />
                        <div className="flex flex-col text-gray-300 lowercase">
                            <p>{versionHistory.formattedDate}</p>
                            <p>{versionHistory.formattedTime}</p>
                        </div>
                        <p className="text-blackish w-[640px] font-[300] truncate">{versionHistory.cliffnoteAction}</p>
                    </div>
                    <Button variant="outline" onClick={() => {
                        window.location.href = `/image/editor/${versionHistory.task_queue_id}`;
                    }} className="text-black border-gray-300 hover:bg-black hover:text-white">View</Button>
                </div>
            ))}
        </div>
    )
}

export default VersionHistoryModal;