import React from "react";
import { useAppSelector } from "../../hooks";
import { USER_TYPES } from "../../lib/constants";
import RightsHolderDashboard from "./artist_dashboard";
import CreatorDashboard from "../creator/creator_dashboard";
import MyGeneratedImages from "../image-generator/my_generated_images";

function Dashboard() {
  const { profile } = useAppSelector((state) => state.user);



  switch (profile.user_type) {
    case USER_TYPES.CREATOR.key:
      {/* OAI-363 */ }
      if (profile?.roles?.includes("AI_IMAGE_CREATOR")) {
        return <MyGeneratedImages />;
      }else {
        return <CreatorDashboard/>;
      }
    case USER_TYPES.RIGHTS_HOLDER.key:
      return <RightsHolderDashboard />;
    default:
      return <div>Dashboard coming soon</div>;
  }

}

export default Dashboard;
