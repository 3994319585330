import React from "react";
import { Panel } from "../models/image_generator";
import {
    CopyIcon,
    DownloadIcon,
    EditAspectRatioIcon,
    EditDirectlyIcon,
    EditPromptIcon,
    SaveIcon,
    Heart,
    UpscaleIcon,
    ThumbsUp,
    HistoryIcon,
    SearchIcon,
    DragIcon
} from "../../../common/icons/icons";


export const PANELS: Omit<Panel, "active" | "component" | "isExpanded">[] = [
    {
        id: "Description",
        name: "Description",
        icon: <EditPromptIcon />,
        description: "Description"
    },
    {
        id: "Versions",
        name: "Versions",
        icon: <CopyIcon />,
        description: "Versions"
    },
    {
        id: "Edit directly",
        name: "Edit directly",
        icon: <EditDirectlyIcon />,
        description: "Edit directly"
    },
    {
        id: "History",
        name: "History",
        icon: <HistoryIcon />,
        description: "History"
    },

    {
        id: "Zoom",
        name: "Zoom",
        isShort: true,
        icon: <SearchIcon />,
        description: "Zoom"
    },
    {
        id: "Drag",
        name: "Drag",
        isShort: true,
        icon: <DragIcon />,
        description: "Move and pan"
    },
    {
        id: "Aspect ratio",
        name: "Aspect Ratio",
        icon: <EditAspectRatioIcon />,
        description: "Aspect ratio"
    },
    {
        id: "Upscale",
        name: "Upscale",
        isShort: true,
        icon: <UpscaleIcon />,
        description: "Upscale"
    },
    {
        id: "Favorite image",
        name: "Favorite image",
        isShort: true,
        icon: <Heart />,
        description: "Favorite"
    },
    {
        id: "Download image",
        name: "Download image",
        isShort: true,
        icon: <DownloadIcon />,
        description: "Download"
    },
    {
        id: "Save image",
        name: "Save image",
        isShort: true,
        icon: <SaveIcon />,
        description: "Save"
    },
    {
        id: "Feedback",
        name: "Feedback",
        isShort: true,
        icon: <ThumbsUp />,
        description: "Feedback"
    },
]