import React from "react";
import Panel from "./panel";
import VersionHistorySlider from "../version_history_slider";
import { Button } from "../../../../common/components/ui/button";
import { VersionHistory } from "../../models/image_generator";


const VersionHistoryPanel = ({
    versionHistory,
    selectedVariant,
    setSelectedVariant,
    showLoadingImage,
    generateVariation,
    closePanel,
    handleToggleExpand,
    expanded
}: {
    versionHistory: VersionHistory[],
    selectedVariant?: VersionHistory | null,
    setSelectedVariant: (variant: VersionHistory) => void,
    showLoadingImage: boolean,
    generateVariation: () => void,
    closePanel?: () => void,
    expanded: boolean | undefined,
    handleToggleExpand: () => void
}) => {

    return (
        <Panel toggleExpanded={handleToggleExpand} isExpanded={expanded} title="Versions" closePanel={closePanel}>
            <div className="space-y-4">
                <VersionHistorySlider versionHistory={versionHistory} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} showLoadingImage={showLoadingImage} />
                <Button onClick={generateVariation} className="w-full" variant="outline">Generate variations</Button>
            </div>
        </Panel>
    )
}

export default VersionHistoryPanel;